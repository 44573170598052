import type { SearchFilters } from '../types/filters'

export const searchFilterKeys = [
  'bodyType',
  'colour',
  'seats',
  'doors',
  'fromEngineSize',
  'toEngineSize',
  'fuelType',
  'make',
  'model',
  'trim',
  'mileage',
  'fromPrice',
  'toPrice',
  'gearbox',
  'fromYear',
  'toYear',
  'driveType',
  'store',
  'tags',
  'features',
  'financeType',
] as const

export const validUrlSegmentFilterKeys = [
  'bodyType',
  'colour',
  'seats',
  'doors',
  'fuelType',
  'make',
  'model',
  'trim',
  'gearbox',
  'driveType',
  'vehicleYear', // Only allow vehicleYear (not fromYear/toYear) in URL segments
] as const

export const nonFilterParamKeys = [
  'sortingCriteria',
  'pageSize',
  'pageNumber',
  'useMonthly',
] as const

export const searchParamKeys = [
  ...searchFilterKeys,
  ...nonFilterParamKeys,
] as const

export const defaultFilters: SearchFilters = {
  make: [],
  model: [],
  trim: [],
  bodyType: [],
  colour: [],
  seats: [],
  doors: [],
  fromEngineSize: [],
  toEngineSize: [],
  fuelType: [],
  mileage: [],
  fromPrice: [],
  toPrice: [],
  gearbox: [],
  fromYear: [],
  toYear: [],
  driveType: [],
  store: [],
  tags: [],
  features: [],
  financeType: [],
}

export const DEFAULT_PAGE_SIZE = 32
export const DEFAULT_SORTING_CRITERIA = 3

export const defaultNonFilterParams = {
  sortingCriteria: DEFAULT_SORTING_CRITERIA,
  pageNumber: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  useMonthly: false,
}
