export { default as CookieConsent } from './lib/cookie-consent.container'
export { segmentSnippet } from './lib/scripts/segment-snippet'
export {
  getCookie,
  getCookieSettings,
  setCookie,
  getCookieConsentPreferences,
  cookieSettings,
  hasCookieConsentBeenSet,
} from './lib/utils'
