export { AnalyticsManager } from './analytics-manager'
export { useAnalytics } from './use-analytics.hook'
export type { AnalyticsState } from './use-analytics.hook'
export {
  EventActions,
  EventCategories,
  EventNames,
  HotJarTriggers,
  SegmentEventsTypes,
  TrackingEventTypes,
} from './types'
export type { TrackingEvent } from './types'
export { addDataDogUserAction } from './add-user-action'
export { preserveReferrerAndCID } from './preserve-session-data'
