import { IEnvironmentFromFile } from './environment.types'

export const environmentDev: IEnvironmentFromFile = {
  ADOBE_DTM: 'launch-EN4784749e433243eb8208fac004e2b1d9-development',
  IDS_AUTH_KEY: 'oxford-user:https://identity-dev.cinch.co.uk/:fe',
  AUTH0_AUTH_KEY:
    'oxford-user:https://auth-dev.cinch.co.uk:GnTyrV9M8uSC94BY0X2PBB9rX5mwmHuU',
  CINCHCARE_SERVICE_URL: 'https://cinchcare.cnv-dev.aws.cinch.co.uk',
  PERMISSION_URL: 'https://permissions.snc-dev.aws.cinch.co.uk/unsubscribe',
  DELIVERY_SERVICE_URL: 'https://delivery.dev.api.cinch.co.uk',
  ORDER_SERVICE_URL: 'https://orders.dev.api.cinch.co.uk',
  PROFILE_SERVICE_URL: 'https://profile.onr-dev.aws.cinch.co.uk',
  SEARCH_SERVICE_URL: 'https://search-api.snc-dev.aws.cinch.co.uk',
  SEARCH_VALUE_PROPOSITIONS: 'true',
  SEGMENT_KEY: '2EDvvodmSFPCpQV2U4a7GtoV2sxRDNsX',
  STATIC_ASSETS_URL: 'https://assets-dev.cinch.co.uk',
  IDENTITY_SERVICE_URL: 'https://api-dev.cinch.co.uk/idt/v1',
  AUTH0_SERVICE_URL: 'https://auth-dev.cinch.co.uk',
  AUTH0_DATABASE_NAME: 'Username-Password-Authentication',
  AUTH0_CLIENT_ID: 'GnTyrV9M8uSC94BY0X2PBB9rX5mwmHuU',
  PROMO_BANNER_NAME: 'sale',
  CINCH_URL: 'https://cambridge.preview.aws.cinch.co.uk',
  CINCH_APP_URL: 'https://mobile.snc-dev.aws.cinch.co.uk',
  TRUSTPILOT_URL: 'https://trustpilot.snc-dev.aws.cinch.co.uk',
  STORYBLOK_BFF_COMPONENT: 'https://storyblok-bff.snc-dev.aws.cinch.co.uk',
  CONSUMER_VALUATIONS_SERVICE_URL:
    'https://consumer-valuations.cnv-dev.aws.cinch.co.uk',
  CINCH_CHARGE_CARD_REQUEST_URL:
    'https://extras-cinch-charge-card.cnv-dev.aws.cinch.co.uk',
  CINCH_CHARGE_CARD_REQUEST_API_KEY: '9IHPexNUQG1RAnKt6D0Aj7gCpMbVjklaaLMir7vG',
  PRODUCT_DETAIL_SERVICE_URL: 'https://product.dev.api.cinch.co.uk',
  RESERVATION_STATUS_URL:
    'https://orders.dev.api.cinch.co.uk/reservation-status',
  INVENTORY_SERVICE_HISTORY_URL:
    'https://service-history.dev.api.cinch.co.uk/basic',
  IOS_APP_STORE_URL:
    'https://apps.apple.com/gb/app/cinch-cars-without-the-faff/id1641060889',
  ANDROID_APP_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.cinchapp',
  ADMIN_FEE_RETURN_TO_CHECKOUT: 'true',
  ADMIN_FEE_VALUE: '9900',
  ADMIN_FEE_LOYALTY: 'true',
  UNSUBSCRIBE_SERVICE_URL:
    'https://communication-v2-user-prefs.dev.api.cinch.co.uk/unsubscribe',
  UNSUBSCRIBE_MINIMUM_DURATION: '3000',
  CHECKOUT_RESERVATIONS_DECOUPLING: 'true',
  CHECKOUT_RESERVATIONS_DECOUPLING_PHASE_TWO: 'false',
  RESERVATION_SERVICE_URL:
    'https://reservations.cnv-dev.aws.cinch.co.uk/source/orders',
  DELIVERY_SLOT_AVAILABILITY_SERVICE_URL:
    'https://delivery.dev.api.cinch.co.uk/delivery-slots',
  LOGISTICS_FEES_SERVICE_URL: 'https://logistics-fees.snp-dev.aws.cinch.co.uk',
  FINANCE_APPLICATION_SERVICE_URL:
    'https://finance-application.dev.api.cinch.co.uk',
  FINANCE_QUOTE_SERVICE_URL: 'https://finance-quote.pnf-dev.aws.cinch.co.uk',
  FINANCE_SOFT_SEARCH_URL_PATH: '/car-finance/check-eligibility',
  PAYMENT_LINK_SERVICE_URL: 'https://payment-link.cnv-dev.aws.cinch.co.uk',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_oWH3UdQU6FjMhxofexsj2LbB00JJTg5bhT',
  GAP_SERVICE_URL: 'https://gap-insurance.cnv-dev.aws.cinch.co.uk',
  SERVICING_AND_WARRANTY_FLAG: 'false',
  DISABLE_CINCHCARE: 'false',
  GAP_INSURANCE_FLAG: 'true',
  CUSTOMER_DATA_API_KEY: '579faa1b-87e1-44c0-bdbb-5ed3e4f2239c',
  CUSTOMER_DATA_API_URL: 'https://customer-data.dev.api.cinch.co.uk',
  ENABLE_CINCH_APP_USER_AGENT_IN_USED_CARS_SSR: 'true',
  SEARCH_MULTIPLE_IMAGES: 'true',
  ENABLE_ZENDESK_SCRIPT: 'true',
  VEHICLE_CARD_V2: 'true',
  NEXT_BEST_ACTION: 'true',
  SEARCH_STORE_LOCATIONS: 'true',
  VEHICLE_OWNERSHIP_SERVICE_URL:
    'https://vehicle-ownership.onr-dev.aws.cinch.co.uk',
  ENABLE_ADMIN_FEE_COMPLIANCE: 'true',
  ENABLE_BUY_NOW_CTA_MESSAGING: 'true',
  ENABLE_EMAIL_QUOTE: 'true',
  HOMEPAGE_FAVOURITING: 'true',
  ENABLE_PRODUCT_DETAIL_PAGE_VARIANT: 'true',
  ENABLE_QUOTES_MARKETING: 'true',
  ENABLE_PX_ON_HOMEPAGE_HERO: 'true',
  SEARCH_FINANCE_TYPE_FILTER: 'true',
  SHOW_QUOTE_TERM_MONTHS: 'true',
  SHOW_SCORELESS_TRUSTPILOT: 'true',
  SOFT_SEARCH_CHANGES: 'true',
  SHOW_CINCH_COVER_IN_PROFILE: 'true',
  SHOW_TYRE_INSURANCE_IN_PROFILE: 'true',
  SHOW_MULTI_TERM_VAPS_IN_PROFILE: 'true',
  SHOW_SELF_SERVICE_RENEWALS_V2: 'true',
  BANK_SERVICE_URL: 'https://bank-details.cnv-dev.aws.cinch.co.uk',
  VAP_SERVICE_URL: 'https://yybmpue166.execute-api.eu-west-1.amazonaws.com/dev',
  USE_REFRESH_TOKEN: 'true',
  SEARCH_RECENTLY_VIEWED_VEHICLES: 'true',
  SHOW_HELP_AND_SUPPORT_CHANGES: 'true',
  HELP_AND_SUPPORT_URL: 'https://help.cinch-dev.co.uk/hc/en-gb/',
  SEARCH_FINANCE_CALCULATOR_CTA: 'true',
  ENABLE_SEGMENT_TRACKING_IN_USED_CARS: 'true',
  LOGISTICS_FEE_SINGLE_TIER: 'true',
}
